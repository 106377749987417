import { useEffect, useState } from "react";
import styled from "styled-components";
import "./style.css";
import Countdown from "react-countdown";
import { Button, Container, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

      
    //{wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>}
    //{wallet && <p>Total Available: {itemsAvailable}</p>}
    //{wallet && <p>Redeemed: {itemsRedeemed}</p>}


//          <p>Fair mint (see technical details)</p>

    //     <div className={`container`}>
    // 	  <div className={`title`}><h2>Mint</h2></div>
    // 	  {wallet && (
    // <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
    // 	  )}
    //   Mint Price: 2 SOL<br/><br/>
    //   {wallet && <p>Redeemed: {itemsRedeemed} / {itemsAvailable}</p>}
    //  <MintContainer>
    //     {!wallet ? (
    //       <ConnectButton>Connect Wallet</ConnectButton>
    //     ) : (
    //       <MintButton
    //         disabled={isSoldOut || isMinting || !isActive}
    //         onClick={onMint}
    //         variant="contained"
    //       >
    //         {isSoldOut ? (
    //           "SOLD OUT"
    //         ) : isActive ? (
    //           isMinting ? (
    //             <CircularProgress />
    //           ) : (
    //             "MINT"
    //           )
    //         ) : (
    //           <Countdown
    //             date={startDate}
    //             onMount={({ completed }) => completed && setIsActive(true)}
    //             onComplete={() => setIsActive(true)}
    //             renderer={renderCounter}
    //           />
    //         )}
    //       </MintButton>
    //     )}
    //   </MintContainer>
    // 	  <br/>
    // 	  <br/>
    // 	  The mint will finish on 31. October 9pm UTC.<br/><br/>
    // 	  In case there are any NFTs not sold out, the remaining ones will be automatically airdropped to existing holders for free - in that case nothing needs to be done from your side.
    // 	  (You can find more information in our <a target={`_blank`} style={{color:'#ffffff'}} href="https://discord.gg/tMbcsw7BNC">Discord</a>.)
    // 	  </div>

                  //     <li>
                  //   <input type="checkbox" id="list-item-3" />
                  //   <label htmlFor="list-item-3" className="last">Is there a limit to how many I can mint?</label>
                  //   <ul>
                  //     <li>No</li>
                  //   </ul>
                  // </li>
                  // <li>
                  //   <input type="checkbox" id="list-item-4" />
                  //   <label htmlFor="list-item-4" className="last">How do I know this is legit?</label>
                  //   <ul>
                  //     <li>We are glad you asked. Unfortunately with the popularity of Solana there were a few black sheep including some scams going on. We want to be 100% tranparent with you. See Technical Details on how to verify this yourself.</li>
                  //   </ul>
                  // </li>

    
  return (
      <div>
        <div className={`top-color`}>
        <div className={`header`}>
            <div className={`container`}>
              <div className={`header-container`}>
                <div className={`col`}>
                  <div className={`logo`}>
                    <img src={`../images/logo.svg`} />
                  </div>
                </div>
                <div className={`col social-media`}>
                  <ul>
                    <li><a href={'https://twitter.com/ZombieApeClub'} target={`_blank`} ><img src={`../images/twitter.svg`} /></a></li>
                    <li><a href={'https://discord.gg/tMbcsw7BNC'} target={`_blank`} ><img src={`../images/discord.svg`} /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        <div className={`hero-block`} style={{ backgroundImage: "url(../images/zombie-bg.png)" }}>
            <div className={`container`}>
              <h1>999 Truly unique Zombie Apes invading the Metaverse</h1>
          </div>
          </div>
          <div className={`banner-bottom`} >
          <div className={`m-card`}>
          <p>Total Supply: 999</p>
          <p>Mint is closed. Find us on the Secondary Market:<br/><a href="https://magiceden.io/marketplace/zombie_ape_club" target={`_blank`} style={{color:'#ffffff'}}>Magic Eden</a>, <a href="https://solsea.io/collection/61795f3df984ba5d3b7c6d06" target={`_blank`} style={{color:'#ffffff'}}>Solsea</a></p>
      </div>
          <img src={`../images/zombie.png`} />
      </div>

	  
	  
          <div className={`about-block`}>
          <div className={`container`}>
            <div className={`d-flex`}>
              <div className={`col`}>
                <h2>What is unique about the Zombie Ape Club?</h2>
              </div>
              <div className={`col`}>
                <p>The first large scale Profile Picture NFT Project with each individual picture being truly unique. Not just templates of parts randomly layered together on top of each other.</p>
                <p>The first NFT collection ever, that is showcased in published book form and available on Amazon as well as all major bookstores and retailers. <a target={`_blank`} style={{color:'#ffffff'}} href="https://www.amazon.com/Zombie-Ape-Club-NFT-Collection/dp/B09K1TY6RS/">(Show on Amazon)</a></p>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className={`bot-color`} style={{ backgroundImage: "url(../images/bats.png)" }}>
          <div className={`road-map`}>
            <div className={`title`}><h2>Road Map</h2></div>
            <div className={`container`}>
              <div className="timeline">
                <div className="n-container left">
                  <div className="content">
                    <p>Release of Hardcover Book Launch showcasing the entire collection</p>
                    <p><a target={`_blank`} style={{color:'#ffffff'}} href="https://www.amazon.com/Zombie-Ape-Club-NFT-Collection/dp/B09K1TY6RS/">Done!</a></p>
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>Public Mint on 30. October 2021</h2>
          <p>The public mint will be open on this website on 30. October 2021 at 21:00 UTC.</p>
      Done!
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
          <h2>Secondary Market Listing</h2>
	  <p>
	  Find us on the Secondary Market:<br/><a href="https://magiceden.io/marketplace/zombie_ape_club" target={`_blank`} style={{color:'#ffffff'}}>Magic Eden</a>, <a href="https://solsea.io/collection/61795f3df984ba5d3b7c6d06" target={`_blank`} style={{color:'#ffffff'}}>Solsea</a>.<br/>We are also in touch with all other major marketplaces to get the collection listed and verified. These include Digital Eyes, Solanart, Alpha Art and others.</p>
	  Done!
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>Distribution of 100 hardcover books to minters</h2>
                    <p>After the mint 100 high quality Hardcover books showcasing the entire collection - including Your Zombie Ape - are distributed to everyone who minted a Zombie Ape Number that is divisible by 10 (e.g. Zombie Ape #10; Zombie Ape #60; Zombie Ape #980)</p>
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
                    <h2>Airdrop of Companion NFT</h2>
                    <p>One month after the mint, we will airdrop a companion NFT to all holders.</p>
                  </div>
                </div>
                <div className="n-container right">
                  <div className="content">
                    <h2>Re-Invest into the community.</h2>
                    <p>Deflationary floor sweeping: we are dedicating 25% of first year secondary royalties to sweeping the floor. After that the Zombie Ape Club DAO will take over on how to invest 25% of the secondary royalties that go into the treasury for the best use.</p>
                  </div>
                </div>
                <div className="n-container left">
                  <div className="content">
                    <h2>Zombie Ape Club DAO</h2>
                    <p>We will be creating a Zombie Ape Club DAO. The community DAO will be used to vote on the future direction of the project to build value for the project and holders.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className={`faq`}>
            <div className={`title`}>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className={`container`}>
              <div className="wrapper">
          <ul>
	                    <li>
                    <input type="checkbox" id="list-item-1" />
                    <label htmlFor="list-item-1" className="first">Where can I buy a Zombie Ape?</label>
                    <ul>
                      <li>The minting is finished. You can find us on the Secondary Market e.g. <a href="https://magiceden.io/marketplace/zombie_ape_club" target={`_blank`} style={{color:'#ffffff'}}>Magic Eden</a> and <a href="https://solsea.io/collection/61795f3df984ba5d3b7c6d06" target={`_blank`} style={{color:'#ffffff'}}>Solsea</a>.</li>
                    </ul>
                  </li>

                  <li>
                    <input type="checkbox" id="list-item-1" />
                    <label htmlFor="list-item-1" className="first">Are their secondary sale royalties?</label>
                    <ul>
                      <li>Yes, royalties are set at 5%. 25% of those royalties go to the Treasury and will be re-invested to sweep the floor as well as funding community decisions of the DAO.</li>
                    </ul>
                  </li>
                  <li>
                    <input type="checkbox" id="list-item-2" />
                    <label htmlFor="list-item-2">Do I own the Zombie Ape after purchasing?</label>
                    <ul>
                      <li>Yes, of course! Full rights are given to the owner of the wallet address that minted the Zombie Ape.</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`technical`}>
            <div className={`title`}>
              <h2>Technical Details</h2>
            </div>
            <div className={`container`}>
              <div className={`technical-block`}>
                <div className={`col`}>
                  <p>Arweaeve - All image assets are stored on Arweave to stay there forever.
                    See "<a target={`_blank`} style={{color:'#ffffff'}} href="https://arweave.medium.com/nft-permanence-with-arweave-35b5d64eff23">NFT Permanence with Arweave</a>"</p>
                  <p>Metaplex Candy Machine - Fair mint via the Metaplex Candy Machine. Reliable mint that just works.</p>
                </div>
                <div className={`col`}>
                  <p>Our Candy Machine Program on Chain: <a href="https://solscan.io/account/FhGx1HFMBkwMLd4UyF7kb7LEaLa86zMauVL4Lipu9ZAQ" target={`_blank`} style={{color:'#ffffff'}}>FhGx1HFMBkwMLd4UyF7kb7LEaLa86zMauVL4Lipu9ZAQ</a></p>

                  <p>Depending on the wallet you choose, you can validate the on-chain program (the candy machine) your wallet interacts with. You can also validate the configuration with Solana Blockchain exploration tools (e.g. Solscan.io or Solana Explorer).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={`rerity`}>
            <div className={`title`}>
              <h2>Rarity</h2>
            </div>
            <div className={`container`}>
              <div className={`rerity-block`}>
                <div className={`col`}>
                  <p>The low total supply of only 999 NFTs makes them rare by default.</p>
                  <p>Each Zombie Ape is truly unique. Not a random assembly of parts but a unqique piece of art for every single Zombie Ape.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={`team`}>
            <div className={`title`}>
              <h2>Team</h2>
            </div>
            <div className={`container`}>
              <div className={`team-block`}>
                <div className={`col`}>
                  <div className={`image-block`}>
                    <img src={`../images/avatar-1.png`} />
                  </div>
                  <h4>Xavier Leung</h4>
                  <p>Development<br/>
                    @NonFungibleSOL</p>
                </div>
                <div className={`col`}>
                  <div className={`image-block`}>
                    <img src={`../images/avatar-2.png`} />
                  </div>
                  <h4>Protogenes</h4>
                  <p>Art & Design<br/>
                    @protogenes11</p>
                </div>
                <div className={`col`}>
                  <div className={`image-block`}>
                    <img src={`../images/avatar-3.png`} />
                  </div>
                  <h4>Melissa K.</h4>
                  <p>she is doxing-shy</p>
                </div>

              </div>
            </div>
          </div>
          <div className={`footer`}>
            <div className={`container`}>
              <div className={`footer-block`}>
                <div className={`col`}>
                  <h5>Powered by</h5>
                  <ul>
                    <li><img src={`../images/pow-1.svg`}/></li>
                    <li><img src={`../images/pow-2.svg`}/></li>
                    <li><img src={`../images/pow-3.svg`}/></li>
                  </ul>
                </div>
                <div className={`col`}><p>Copyright 2021 by ZombieApe.Club</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
